// FUNCTION TO REDIRECT TO HOME PAGE
export function redirectPage() {
  const redirectHome = document.querySelector('.redirect-countdown');

  if (redirectHome != undefined) {
    let count = 5;

    setInterval(() => {
      if (count >= 0) {
        redirectHome.innerHTML = `${count}s`;
        count--;
      }
    }, 1000)

    setTimeout(() => {
      window.location.href = "/";
    }, 5500);
  }
}
