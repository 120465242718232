export function chooseUnits() {
  const unitsSelect = document.getElementById('choose-units');

  if(unitsSelect) {
    const unitsOptions = unitsSelect.options[unitsSelect.selectedIndex];
    const optionSelected = unitsOptions.value;
    const unitsContainerSelected = document.querySelectorAll('.js-choose-units');

    unitsContainerSelected.forEach((unit) => {
      const dataUnit = unit.getAttribute("data-unit");

      if(dataUnit === optionSelected) {
        unit.classList.add('active');
      } else {
        unit.classList.remove('active');
      }
    });

    unitsSelect.addEventListener('change', () => {
      chooseUnits();
    });
  }
}
