import { activateHeaderStyle, desactivateHeaderStyle } from "./sidebarFixed";

const elementsDOM = document.querySelector("body, html");

export function closeAllNavbarDropdown(except = null) {
  const activeDropdowns = document.querySelectorAll(".navbar-dropdown-list-active");
  const activeNavMenu = document.querySelectorAll(".navbar-link-active");

  activeDropdowns.forEach((dropdown) => {
    if (dropdown !== except) dropdown.classList.remove("navbar-dropdown-list-active");
  });

  activeNavMenu.forEach((navMenu) => {
    if (!except || !navMenu.contains(except)) {
      navMenu.classList.remove("navbar-link-active");
    }
  });

  if (!except) elementsDOM.classList.remove("scroll-lock");
}

export function showNavbarDropdown(dropdown, content) {
  const dropdownSelected = document.querySelectorAll(dropdown);

  if (dropdownSelected) {
    dropdownSelected.forEach((dropdownItem) => {
      dropdownItem.addEventListener("click", (event) => {
        event.stopPropagation();

        const dropdownContent = dropdownItem.querySelector(content);
        if (!dropdownContent) return;

        const isActive = dropdownContent.classList.contains("navbar-dropdown-list-active");
        closeAllNavbarDropdown(isActive ? null : dropdownContent);

        if (!isActive) {
          let nearHeader = dropdownContent.closest("header");
          activateHeaderStyle(nearHeader);
          dropdownItem.classList.add("navbar-link-active");
          dropdownContent.classList.add("navbar-dropdown-list-active");
          elementsDOM.classList.add("scroll-lock");
        }
      });
    });
  }
}

export function showDropdownSub(dropdown, content) {
  const dropdownSelected = document.querySelectorAll(dropdown);
  
  if (dropdownSelected) {
    dropdownSelected.forEach((dropdownItem) => {
      dropdownItem.addEventListener("click", (event) => {
        event.stopPropagation();
        const dropdownContent = dropdownItem.nextElementSibling;
        if (dropdownContent) {
          dropdownItem.classList.toggle("dropdown-subtitle-active");
          dropdownContent.classList.toggle("dropdown-links-list-active");
        }
      });
    });
  }
}


