let swiperBanners;

export function swipersInit() {
  swiperBanners = new Swiper('.swiper-banners', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    speed: 700,
    // autoplay: {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: '.swiper-pagination.banners',
      clickable: true,
    },
  });

  const swiperNewModelsHome = new Swiper('.swiper-new-models-home', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation:{
      nextEl: '.swiper-button-next.new-models-home',
      prevEl: '.swiper-button-prev.new-models-home'
    },
    pagination: {
      el: '.swiper-pagination.models',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  });
  
  const swiperTabsNewModelsHome = new Swiper('.home-models-tabs', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    navigation:{
      nextEl: '.swiper-button-next.new-models-home-tab',
      prevEl: '.swiper-button-prev.new-models-home-tab'
    },
  });

  const swiperTypeModelsHome = new Swiper('.swiper-type-models-home', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    centerInsufficientSlides: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation:{
      nextEl: '.swiper-button-next.type-models-home',
      prevEl: '.swiper-button-prev.type-models-home'
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 15,
      },
      1400: {
        slidesPerView: 7,
        spaceBetween: 15,
      },
    },
  });

  const swiperNewModels = new Swiper ('.swiper-new-model', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next.new-model',
      prevEl: '.swiper-button-prev.new-model',
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1600: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });

  const swiperNewModelsHighlights = new Swiper ('.swiper-new-model-highlights', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    centerInsufficientSlides: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next.new-model-highlights',
      prevEl: '.swiper-button-prev.new-model-highlights',
    },
    pagination: {
      el: '.swiper-pagination.highlights',
      type: "fraction",
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1600: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });


  const swiperNewModelVersion = new Swiper ('.swiper-new-model-version', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.version',
      prevEl: '.swiper-button-prev.version',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      }
    },
  });


  const swiperListPhones = new Swiper('.swiper-list-phones', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next.list-phones-nav',
      prevEl: '.swiper-button-prev.list-phones-nav'
    },
    pagination: {
      el: '.swiper-pagination.list-phones-nav',
      clickable: true,
    },
    breakpoints: {
      440: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: "auto",
      },
    },
  });

  const swiperLocations = new Swiper('.swiper-locations', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.locations',
      prevEl: '.swiper-button-prev.locations',
    },
    pagination: {
      el: '.swiper-pagination.locations',
      clickable: true,
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });



  const blogInternalRelatedSwiper = new Swiper('.blog-internal-related-slider', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    slidesPerView: 1,
    spaceBetween: 30,
    watchOverflow: true,
    watchOverflow :true,
    navigation: {
      nextEl: '.swiper-button-next.blog-internal',
      prevEl: '.swiper-button-prev.blog-internal',
    },
    pagination: {
      el: '.swiper-pagination.blog-internal',
      clickable: true
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }
  });

  
  const swiperUsedModelInternalStamp = new Swiper('.used-model-internal-stamps-slider', {
    clickable: true,
    slidesPerView: 3,
    watchOverflow: true,
    observer:true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow :true,
    pagination: {
      el: '.swiper-pagination.stamp',
      clickable: true
    },
  });

  const swiperUsedModelInternalThumbs = new Swiper('.used-model-internal-slider-thumbs', {
    slidesPerView: 3,
    freeMode: true,
    spaceBetween: 60,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next.used-thumbs',
      prevEl: '.swiper-button-prev.used-thumbs',
    },
    breakpoints: {
      992: {
        slidesPerView: 4,
        spaceBetween: 0,
      }
    }
  });

  const usedModelInternalSwiper = new Swiper('.used-model-internal-slider-content', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.used',
      prevEl: '.swiper-button-prev.used',
    },
    clickable: true,
    thumbs: {
      swiper: swiperUsedModelInternalThumbs,
    },
  });

  const swiperUsedModelInternalRelated = new Swiper('.used-model-internal-related-slider', {
    clickable: true,
    slidesPerView: 1,
    watchOverflow: true,
    observer:true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow :true,
    pagination: {
      el: '.swiper-pagination.related',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next.related',
      prevEl: '.swiper-button-prev.related',
    },
    breakpoints: {
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
      }
    }
  });


  const swiperHighlights = new Swiper('.categories-acessories-swiper', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next.highlights',
      prevEl: '.swiper-button-prev.highlights',
    },
  });

  const swiperCategories = new Swiper ('.swiper-categories', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 4,
    navigation: {
      nextEl: '.swiper-button-next.categories',
      prevEl: '.swiper-button-prev.categories',
    },
    breakpoints: {
      992: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  });


  const newModelsDropdown = new Swiper ('.swiper-dropdown', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1.4,
    navigation: {
      nextEl: '.swiper-button-next.dropdown',
      prevEl: '.swiper-button-prev.dropdown',
    }
  });


  
}

function limitSlidesVisibility(swiper) {
  const maxVisibleSlides = 7;  // Define o número máximo de slides a serem exibidos
  let totalWidth = 0;

  swiper.slides.forEach((slide, index) => {
    totalWidth += slide.offsetWidth;
    if (index >= maxVisibleSlides || totalWidth >= swiper.width) {
      slide.style.display = 'none';
    } else {
      slide.style.display = '';
    }
  });
}

export function toggleAutoplay() {
  const playPauseButton = document.getElementById('playPauseButton');
  const paginationIconPause = document.getElementById('paginationIconPause');
  const paginationIconPlay = document.getElementById('paginationIconPlay');

  // Verifica se o swiperBanners está rodando
  if (swiperBanners.autoplay.running) {
    swiperBanners.autoplay.stop();
    paginationIconPause.classList.add('hidden');
    paginationIconPlay.classList.remove('hidden');
  } else {
    swiperBanners.autoplay.start();
    paginationIconPause.classList.remove('hidden');
    paginationIconPlay.classList.add('hidden');
  }
}

