import { lockScreenScroll } from '../utils/treatment';
import { hideFixedForm } from './actionsForm';

const btnCallMenu = document.querySelector('.js-menu-button');
const sidebarFixed = document.querySelector('.js-sidebar-container');
const formMain = document.querySelector('.form-main');
const unitsSelect = document.querySelector('.units-select');
const btnMainMenu = document.querySelector('.btn-mainMenu');
const mainHeader = document.querySelector('header.header');

// FUNCTION TO CONTROL BEHAVIOR OF SIDEBAR FIXED
export function sidebarFixedBehavior(button,menu) {
  let menuSelected = document.querySelectorAll(menu);
  let buttonMenu = document.querySelector(button);

  menuSelected.forEach((menu) => {

    buttonMenu.addEventListener('click', () => { 

      // get header style from header near the button
      let nearHeader = buttonMenu.closest('header');
      toggleHeaderStyle(nearHeader);

      menu.classList.toggle('open-menu');

      let menuIcon = buttonMenu.querySelector('i');

      if (menu.classList.contains('open-menu')) {
        menuIcon.classList.remove('ai-menu-light');
        menuIcon.classList.add('ai-close-light');
        lockScreenScroll();
      } else {
        menuIcon.classList.remove('ai-close-light');
        menuIcon.classList.add('ai-menu-light');
        lockScreenScroll();
      }

      window.addEventListener('message', function (event) {
        if (event.data.type === 'overlay' && event.data.action === 'close') {
          menu.classList.remove('open-menu');
          menuIcon.classList.remove('ai-close-light');
          menuIcon.classList.add('ai-menu-light');
        }
      });

      if (formMain && formMain.classList.contains('form-content-fixed')) {
        hideFixedForm();
      }

      if (unitsSelect && menu.classList.contains('fixed')) {
        sidebarFixed.classList.toggle('fixed');
      }

    });
  });
}

export function toggleHeaderStyle(nearHeader) {
  let headerStyle = nearHeader.getAttribute('data-style');

  if (headerStyle === 'transparent') {
    nearHeader.classList.toggle('transparent-bg');
    nearHeader.classList.toggle('open-menu');
  }
}

export function activateHeaderStyle(nearHeader) {
  let headerStyle = nearHeader.getAttribute('data-style');

  if (headerStyle === 'transparent') {
    nearHeader.classList.remove('transparent-bg');
    nearHeader.classList.add('open-menu');
  }
}

export function desactivateHeaderStyle(nearHeader) {
  let headerStyle = nearHeader.getAttribute('data-style');

  if (headerStyle === 'transparent') {
    nearHeader.classList.add('transparent-bg');
    nearHeader.classList.remove('open-menu');
  }
}

// FUNCTION TO HIDE SIDEBAR FIXED, IF ANOTHER TARGET IS ACTIVE IN DESKTOP
export function hideSidebarFixed(menu) {
  let menuSelected = document.querySelectorAll(menu);

  menuSelected.forEach((menu) => {
    let menuIcon = menu.querySelector('i');
    let menuText = menu.querySelector('span')

    if (menuIcon.classList.contains('icon-menu-light')) {
      menuIcon.classList.add('icon-fechar-light')
      menuIcon.classList.remove('icon-menu-light')
    } else {
      menuIcon.classList.remove('icon-fechar-light')
      menuIcon.classList.add('icon-menu-light')
    }

    if (menuText) {
      if (menuText.innerText === 'Menu') {
        menuText.innerHTML = 'Fechar'
      } else {
        menuText.innerHTML = 'Menu'
      }
    }

  });

  sidebarFixed.classList.toggle('d-flex');
}
