// IMPORT LIBS
import Swiper from 'swiper';
import noUiSlider from 'nouislider';
import bootstrapBundle from '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import "regenerator-runtime/runtime";

window.Swiper = Swiper;
window.noUiSlider = noUiSlider;
window.bootstrapBundle = bootstrapBundle;

// NEED TO USE "REQUIRE" IN THIS LIBRARY
const fancybox = require('../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox');
window.fancybox = fancybox;

// IMPORT CLASSES
// import Used from './classes/Used';

// IMPORT FUNCTIONS
import { tabsCreate, tabsInit } from './functions/tabs';
import { swipersInit, toggleAutoplay } from './functions/sliders';
import { accordionsCreate, controlAccordionsFilter } from './functions/accordions';
import { showPartialsFixed } from './functions/partialsFixed';
import { buttonCotationBehavior, buttonFilterBehavior } from './functions/actionsForm';
import { showNavbarDropdown, showDropdownSub, closeAllNavbarDropdown } from './functions/dropdowns';
import { sidebarFixedBehavior } from './functions/sidebarFixed';
import { controlDate } from './functions/controlDate';
import { redirectPage } from './functions/redirectPage';
import { handleSetMoreHeight } from './functions/setHeights';
import { chooseUnits } from './functions/chooseLocation';
import { chooseCategory } from './functions/chooseCategories';
import { toggleFormCaptation } from './functions/formSetup.js';
import { showCustomcardExpanded } from './functions/customCard.js';
import { setupSearchModel, initialFetchFilteredCars, setupConditionCheckboxes, fetchFilteredCars } from './functions/actionsFilters';
import { checkUrlParams, setupPremiumSelectionActions } from './functions/premiumSelectionActions.js';


const bodyClassList = document.querySelector('body').dataset.page;
const isUsedExternalLink = document.getElementById('used-external-link');

if (!isUsedExternalLink) {
  // Inicializando funções de filtro novos/seminovos se estiver na página de seminovos
  if ((bodyClassList === 'site-estoque-0km') || (bodyClassList === 'site-seminovos')) {

    var conditionVehicle = 'used';

    if (bodyClassList === 'site-seminovos') {

      window.addEventListener('onFiltersLoaded', setupPremiumSelectionActions);
      window.addEventListener('onGetFilteredCars', checkUrlParams);

      setupPremiumSelectionActions();
    }

    if (bodyClassList === 'site-estoque-0km') {
      conditionVehicle = 'new';
    }

    setupSearchModel(conditionVehicle);
    initialFetchFilteredCars();
  } else if (bodyClassList === 'site-bmw-seminovos-certificados') {
    const filters = {
      brand: 'BMW',
      limit: 4,
      optionals: "BMW Motorrad Premium Selection"
    };

    fetchFilteredCars(filters)
    window.addEventListener('finishRender', enableCardWrapper);

    function enableCardWrapper() {
      let totalCars = document.getElementById('totalCars').innerText;

      if (totalCars && totalCars != '0') {
        let premiumSelectionCardsWrapper = document.getElementById('premiumSelectionCardsWrapper');
        premiumSelectionCardsWrapper.classList.remove('d-none');
      }
    }
  }

}

setTimeout(() => {
  // FUNCTION TO SET MORE HEIGHT AMONG THE ELEMENTS OF SAME TYPE
  handleSetMoreHeight('.js-institucional-description', 992);
  handleSetMoreHeight('.js-promotion-description', 992);
  handleSetMoreHeight('.js-new-model-description', 992);
  handleSetMoreHeight('.js-new-model-highlight-title', 992);

}, 1000);

//Mostra o conteúdo oculto do custom-card
showCustomcardExpanded();

// FUNCTIONS TO CREATE AND INIT TABS
tabsCreate();
tabsInit();


// FUNCTION TO CREATE AND INIT ACCORDIONS
accordionsCreate();

// FUNCTION TO INIT CHOOSE LOCATION
chooseUnits();

// FUNCTION TO INIT CHOOSE CATEGORY ON PAGE PIECES
chooseCategory('choose-category-clothes', '.js-choose-category');
chooseCategory('choose-category-new-models', '.js-choose-category');

// FUNCTION TO CREATE AND INIT SWIPERS
swipersInit();

// FUNCTION TO CONTROL HEADER/FOOTER FIXED
window.addEventListener('scroll', () => {
  showPartialsFixed('.header', 'show-header-scrolled', 'transparent-bg');
  showPartialsFixed('.footer-fixed', 'show-footer-scrolled', '');
});

// FUNCTION TO CONTROL BEHAVIOR OF COTATION BUTTONS
buttonCotationBehavior('.button-call-form');
buttonCotationBehavior('.button-product-cotation');
buttonCotationBehavior('.button-services-cotation');

// FUNCTION TO CONTROL BEHAVIOR OF FILTERS BUTTONS
buttonFilterBehavior('.button-call-filter');

// FUNCTION TO CONTROL FORM'S CAPTATION LOCATION
toggleFormCaptation($('button[data-section]'));


  // Fecha dropdowns ao clicar fora, mas mantém se for um submenu
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".navbar-dropdown-list, .dropdown-content-mobile, .dropdown-content-desktop")) {
      closeAllNavbarDropdown();
    }
  });

// Inicializa os dropdowns
showNavbarDropdown(".navbar-link.mega_menu", ".navbar-dropdown.megaMenuDropdown");
showNavbarDropdown(".sidebar-link.mega_menu", ".navbar-dropdown.megaMenuDropdown");
showNavbarDropdown(".navbar-link.dropdownListBtn", ".dropdownList");
showDropdownSub(".dropdown-subtitle", ".dropdown-links-list");

// FUNCTION TO CONTROL BEHAVIOR OF SIDEBAR FIXED
sidebarFixedBehavior('.btn-mainMenu', '.js-sidebar-button');
sidebarFixedBehavior('.btn-sideMenu', '.js-sidebar-button');

// FUNCTION TO INSERT CURRENT DATE IN INPUTS [TYPE=DATE]
controlDate('input[type="date"]');

// FUNCTION TO REDIRECT TO HOME PAGE
redirectPage();

//FUNCTION TO CHANGE FANCYBOX IN GALLERY NEW MODEL
$(".thumbOutsideGalleryNewCars").on("click", function () {
  var imagePath = $(this).attr("src");
  $("#galleryOutsideFirstImage").attr("src", imagePath);
  $("#gallery-outside a.fancybox").attr("href", imagePath);
});

$(".thumbInsideGalleryNewCars").on("click", function () {
  var imagePath = $(this).attr("src");
  $("#galleryInsideFirstImage").attr("src", imagePath);
  $("#gallery-inside a.fancybox").attr("href", imagePath);
});

setFamily();
getModelFromParams();
changeTestDriveImage();

function setFamily() {
  let searchParams = new URLSearchParams(window.location.search);
  const modelname = searchParams.get('modelname');
  const selector = ".my-tabs[data-content='" + modelname + "']";
}

function getModelFromParams() {
  let searchParams = new URLSearchParams(window.location.search);
  const modelname = searchParams.get('modelname');

  if (modelname) {
    $('select#model').val(modelname);
    const imgWrapper = $('form .test-drive-form-img img');

    const img = $('select#model').find('option:selected').attr('data-image');
    imgWrapper.attr('src', img);
  }
}

function changeTestDriveImage() {
  const myInput = $('select#model');
  const imgWrapper = $('form .test-drive-form-img img');
  myInput.on('change', function (e) {
    const img = $(e.target).find('option:selected').attr('data-image');
    imgWrapper.attr('src', img);
  });
}


//Function to controll faq collapse
$('.faq-collapse-header').on('click', function () {
  $('.faq-collapse').not($(this).parent()).removeClass('open');

  $(this).parent().toggleClass('open');
});


// FUNCTION TO UP TOP PAGE
$('.btn-scroll-top').on('click', function () {
  $('html,body').animate({ scrollTop: 0 }, '300')
});

// FUNCTION TO ADD OVERLAY ON MODALS
function popUp(selector) {
  $('main').addClass('scroll-lock');
  selector.fadeIn('fast');
}

function popClose(form) {
  form ? $('.modal-form').fadeOut() : $('.modal-overlay').fadeOut();
  $('main').removeClass('scroll-lock');
}

// FUNCTION TO PASS INFORMATION TO POP-UP PROMOTIONS
function modalInject(data, target) {
  const { title, img, text } = data;
  $(target).find('.promotion-title').html(title);
  $(target).find('.promotion-img').attr('src', img);
  $(target).find('.promotion-text').html(text);
  popUp($(target));
}

$('.btn-open-modal').on('click', function () {
  const el = $(this).data('target');
  const data = $(this).data('json');
  data ? modalInject(data, el) : popUp($(el));
})

// FUNCTION TO OPEN MODALS
$('.modal-overlay, .btn-close-modal').on('click', function (e) {
  $(e.target).hasClass('form-close') ? popClose() : false
}).on('click', '.pop-up-content', function (event) {
  event.stopPropagation()
})

// FUNCTION TO CONTROL PAGINATIONS
let currentPage = 1;
let currentOrd = 'asc';
let order = 'id';

$('.order-control').on('click', function () {
  search(currentPage);
});

$('.page-link').on('click', function () {

  currentPage = $(this).data('page');
  $('.page-link').removeClass("active");
  $(this).addClass("active");
  search(currentPage);
});

$('.page-link').on('click', function () {
  currentPage = $(this).data('page');
  $('.page-item').removeClass("active");
  $(this.parentElement).addClass("active");

  if (currentPage != "1") {
    $('.left-arrow').removeClass("disabled");
  } else {
    $('.left-arrow').addClass("disabled");
  }

  search(currentPage);
});

function search(pageNum = 1) {
  async function busca() {
    currentOrd = $("#order").val();
    let method = $("#pageId").val();
    let category = $("#category").val();

    if (!order) {
      order = 'id'
    }

    if (!currentOrd) {
      currentOrd = 'asc'
    }

    if (method == 'products') {
      $('#promotions').css('filter', 'blur(5px)');
      const result = await $.request('onGetProducts', {
        data: { order: order, direction: currentOrd, page: pageNum, limit: 9, category: category },
        loading: '.loader-wrapper',
        complete: function (res) {
          $('#promotions').css('filter', 'unset');
        }
      });
      return result;
    }


    if (method == 'promotions') {
      const result = await $.request('onGetPromotions', {
        data: { order: currentOrd, page: pageNum, limit: 9 },
        complete: function (res) {
        }
      });
      return result;
    }

  }

  $('#categories,#order').on('change', function (event) { // catch the form's submit event
    $.ajax({ // create an AJAX call...

      data: $('#formBlog').serialize(), // get the form data
      type: $(this).attr('GET'), // GET or POST
      url: "/blog-post-page", // the file to call
      success: function (response) { // on success..
        $('#here').html(response); // update the DIV
        handleSetMoreHeight('.js-blog-title', 992);
        handleSetMoreHeight('.js-blog-desc', 992);
      }
    });
    return false; // cancel original event to prevent form submitting
  });

  let a = busca().then(data => $("#promotions").html(data.result)).finally(
    function () {
      buttonCotationBehavior('.button-product-cotation');

      $('.btn-open-modal').on('click', function () {
        const el = $(this).data('target');
        const data = $(this).data('json');
        data ? modalInject(data, el) : popUp($(el));
      });

      $('.page-item').on('click', function () {
        const data = $(this).find('[data-page]').data('page')
        search(data);
      });
    }
  );
}
