// FUNCTION TO CONTROL HEADER/FOOTER FIXED
export function showPartialsFixed(partial, classToAdd, classToRemove) {
  const partialSelected = document.querySelectorAll(partial);
  
  partialSelected.forEach((partial) => {

    let partialStyle = partial.getAttribute('data-style');
  
    if (!partial.classList.contains('open-menu') && !partial.classList.contains('open-form')) {
      if (window.scrollY > 150) {
        partial.classList.add(classToAdd);
  
        if (partialStyle === 'transparent') {
          partial.classList.remove('transparent-bg');
        }
  
      } else {
        partial.classList.remove(classToAdd);
        
        if (partialStyle === 'transparent') {
          partial.classList.add('transparent-bg');
        }
      }
    }

  });
}