export function chooseCategory(idSelect, content) {
    const categoriesSelect = document.getElementById(idSelect);

    if(categoriesSelect) {
      const unitsOptions = categoriesSelect.options[categoriesSelect.selectedIndex];
      const optionSelected = unitsOptions.value;
      const categoriesContainerSelected = document.querySelectorAll(content);
  
      categoriesContainerSelected.forEach((category) => {
        const dataCategory = category.getAttribute("data-category");
  
        if(dataCategory === optionSelected) {
          category.classList.add('active');
        } else {
          category.classList.remove('active');
        }
      });
  
      categoriesSelect.addEventListener('change', () => {
        chooseCategory(idSelect, content);
      });
    }
  }
  