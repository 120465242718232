// FUNCTION TO INSERT CURRENT DATE IN INPUTS [TYPE=DATE]
export function controlDate(type) {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  const currentDateFull = `${year}-${month}-${day}T${hour}:${minutes}`
  const currentDate = `${year}-${month}-${day}`;

  const inputDate = document.querySelectorAll(type);
  const inputTime = document.querySelectorAll('input[type="time"]');

  inputDate.forEach((input) => {
    if(type === 'input[type="datetime-local"]') {
      input.value = currentDateFull;
      input.min = currentDateFull;

      input.addEventListener('blur', () => {
        if(input.value < currentDateFull) {
          input.value = currentDateFull;
        }
      });
    } else {
      input.value = currentDate;
      input.min = currentDate;

      input.addEventListener('blur', () => {
        if(input.value < currentDate) {
          input.value = currentDate;
        }
      });
    }
  });

  inputTime.forEach((time) => {
    time.addEventListener('blur', () => {
      const inputTimeError = document.querySelector('span.input-time-error');

      if (time.value < time.min || time.value > time.max) {
        time.value = '';

        const inputTimeErrorElement = `<span class="input-time-error">Escolher um horário das ${time.min} às ${time.max}.</span>`;
        if(!inputTimeError) {
          time.insertAdjacentHTML('beforebegin', inputTimeErrorElement);
        }
      } else {
        inputTimeError.remove();
      }
    });
  });
}
