import { lockScreenScroll } from '../utils/treatment';
import { hideSidebarFixed } from './sidebarFixed';
import { showPartialsFixed } from './partialsFixed';

const btnCallForm = document.querySelectorAll('.button-call-form');
const btnCallFilter = document.querySelectorAll('.button-call-filter');
const closeFormMobile = document.querySelectorAll('.btn-close-form');
const closeFilterMobile = document.querySelectorAll('.btn-close-filter');
const formMain = document.querySelector('.form-main');
const formBanner = document.querySelector('.form-banner');
const filterHeader = document.querySelector('.header-filter');

const formMainMobile = document.querySelector('.form-main-mobile');
const filterMainMobile = document.querySelector('.filter-main-mobile');
const selectModelsWrapper = document.querySelectorAll("select[name=produto]");
const selectDepartmentWrapper = document.querySelectorAll("select[name=department]");

const headerScrolled = document.querySelector('.header-fixed');
const sidebarFixed = document.querySelector('.sidebar-fixed');

const pageURLParameter = window.location.pathname;


// FUNCTION TO CONTROL BEHAVIOR OF COTATION BUTTONS
export function buttonCotationBehavior(button) {
  let buttonSelected = document.querySelectorAll(button);

  buttonSelected.forEach((btn) => {
    btn.addEventListener('click', () => {

      if(filterHeader && filterHeader.classList.contains('filter-header-fixed')) {
        hideFixedFilter();
        lockScreenScroll();
      }

      if (sidebarFixed && sidebarFixed.classList.contains('d-flex')) {
        hideSidebarFixed('.sidebar');
        lockScreenScroll();
      }

      if (btn.classList.contains('button-product-cotation')) {
        const dataModel = btn.getAttribute("data-model");
        const dataId = btn.getAttribute("data-id");
        const dataDepartment = btn.getAttribute("data-department");

        if (dataId)
          swapSelect(dataId, dataModel);

          selectModelsWrapper.forEach((select) => {
          select.value = dataModel;
        });

        if(dataDepartment) {
          swapSelectDepartment(dataDepartment);

          selectDepartmentWrapper.forEach((select) => {
            select.value = dataDepartment;
          });
        }

        if (window.innerWidth >= 992) {
          if (!headerScrolled.classList.contains('show-header-scrolled')) {
            setTimeout(() => {
              if (pageURLParameter == '/novos') {
                window.scrollTo(0, 130);
              } else {
                window.scrollTo(0, 710);
              }
            }, 50);

            showPartialsFixed('.header-fixed', 'show-header-scrolled', 'hide-header-scrolled');
          }
        }

        showFixedForm();
        lockScreenScroll();

      } else if(btn.classList.contains('button-services-cotation')) {
        const dataModel = btn.getAttribute("data-model");

        selectModelsWrapper.forEach((select) => {
          select.value = dataModel;
        });

      } else {
        showFixedForm();
        lockScreenScroll();
      }
    });
  });
}

// FUNCTION TO CONTROL BEHAVIOR OF FILTERS BUTTONS
export function buttonFilterBehavior(button) {
  let buttonSelected = document.querySelectorAll(button);

  buttonSelected.forEach((btn) => {
    btn.addEventListener('click', () => {

      if(formBanner && formBanner.classList.contains('form-content-fixed')) {
        hideFixedForm();
        lockScreenScroll();
      }

      if (sidebarFixed && sidebarFixed.classList.contains('d-flex')) {
        hideSidebarFixed('.sidebar');
        lockScreenScroll();
      }

      showFixedFilter();
      lockScreenScroll();
    });
  });
}

// FUNCTION TO SHOW FIXED FORM
function showFixedForm() {
  btnCallForm.forEach((btn) => {
    btn.innerHTML === '<i class="ai-close-light"></i> Fechar'
    ? btn.innerHTML = `<i class="ai-message-box-light"></i><span>Solicitar cotação</span>`
    : btn.innerHTML = '<i class="ai-close-light"></i> Fechar';
  });

  let mainHeader = document.querySelector('header.header');
  mainHeader.classList.toggle('open-form');
  formBanner.classList.toggle('form-content-fixed');
}

// BUTTON TO CLOSE FORM IN MOBILE
closeFormMobile.forEach((btnClose) => {
  btnClose.addEventListener('click', () => {
    formMainMobile.classList.remove('d-flex');
    lockScreenScroll();
  });
});

function swapSelect(id, value) {
  let html = document.getElementById(id);
    
  if (!html) {
    return
  }

  $('.swap-select').html(html.innerHTML);
  $('.swap-select').find('#carroNovo').val(value);
}

function swapSelectDepartment(value){
  let html = document.getElementById("departments");

  if (!html) {
    return
  }

  $('.swap-select-department').html(html.innerHTML);
  $('.swap-select-department').find('#department').val(value);
}

// FUNCTION TO HIDE FORM, IF ANOTHER TARGET IS ACTIVE IN DESKTOP
export function hideFixedForm() {
  btnCallForm.forEach((btn) => {

    btn.innerHTML === '<i class="icon-fechar-regular text-white mr-2"></i>Fechar'
    ? btn.innerHTML = "Solicitar cotação"
    : btn.innerHTML = '<i class="icon-fechar-regular text-white mr-2"></i>Fechar';

    //btn.classList.toggle('btn-ternary');
  });

  formBanner.classList.toggle('form-content-fixed');
}

// FUNCTION TO SHOW FIXED FILTER
function showFixedFilter() {
  if (window.innerWidth >= 992) {
    btnCallFilter.forEach((btn) => {

      btn.innerHTML === '<i class="icon-fechar-regular text-white mr-2"></i>Fechar'
      ? btn.innerHTML = `<i class="icon-lupa-regular"></i> Buscar veículo`
      : btn.innerHTML = '<i class="icon-fechar-regular text-white mr-2"></i>Fechar';

      //btn.classList.toggle('btn-quaternary');
    });

    filterHeader.classList.toggle('filter-header-fixed');
  } else {
    filterMainMobile.classList.add('d-flex');
  }
}

// BUTTON TO CLOSE FILTER IN MOBILE
closeFilterMobile.forEach((btnClose) => {
  btnClose.addEventListener('click', () => {
    filterMainMobile.classList.remove('d-flex');
    lockScreenScroll();
  });
})


// FUNCTION TO HIDE FILTER, IF ANOTHER TARGET IS ACTIVE IN DESKTOP
export function hideFixedFilter() {
  btnCallFilter.forEach((btn) => {

    btn.innerHTML === '<i class="icon-fechar-regular text-white mr-2"></i>Fechar'
    ? btn.innerHTML = `<i class="icon-lupa-regular"></i> Buscar veículo`
    : btn.innerHTML = '<i class="icon-fechar-regular text-white mr-2"></i>Fechar';

    //btn.classList.toggle('btn-quaternary');
  });

  filterHeader.classList.toggle('filter-header-fixed');
}


