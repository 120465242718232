export function handleSetMoreHeight(elements, breakpoint) {
  if(window.innerWidth > breakpoint) {
    const elementSelect = document.querySelectorAll(elements);
    const elementsHeightArray = [];

    elementSelect.forEach((element) => {
      const elementHeight = element.offsetHeight;
      elementsHeightArray.push(elementHeight);
    });

    const elementsHeightArrayMax = Math.max(...elementsHeightArray);

    elementSelect.forEach((element) => {
      element.style.cssText = `height: ${elementsHeightArrayMax}px;`
    });
  }
}
