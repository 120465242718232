
export function toggleFormCaptation(buttons) {
    const captationInit = $('input[data-dynamic="true"]').val();

    if (buttons) {
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', () => {
                if (!buttons[i].classList.contains('form-fixed-open')) {
                    toggleBtnsState(buttons.toArray(), 'add')
                    $('input[data-dynamic="true"]').val(`${captationInit}-${buttons[i].getAttribute('data-section')}`);
                } else {
                    toggleBtnsState(buttons.toArray(), 'remove');
                    $('input[data-dynamic="true"]').val(`${captationInit}`);
                }

            })
        }
    }

}

function toggleBtnsState(btnsArray, state) {
    btnsArray.forEach(btn => {
        if (state === 'remove') {
            btn.classList.remove('form-fixed-open');
        } else {
            btn.classList.add('form-fixed-open');
        }
    })
}