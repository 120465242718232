export function tabsCreate() {
  $(".my-tabs").click(function(){
      const tab_group = $(this).data('rel')
      const tab_content = $(this).data('content')
      const tab_stuff = $(this).data('parcel')
      const content = $("#"  + $(this).attr("data-content"))

      $(".my-tabs[data-rel='"+ tab_group +"']").removeClass("active")
      $(this).addClass("active")
      $(".my-tabs[data-content='"+ tab_content  +"']").addClass("active")

      $(".my-tabs-content[data-rel='"+ tab_group +"']").hide()
      $(".my-tabs-content[data-stuff='"+ tab_stuff +"']").show()
      content.fadeIn();

      if (content.find(".my-tabs").length > 0) {
        const subTab = content.find(".my-tabs")
        const subTabContent = content.find(".my-tabs-content")
        subTabContent.hide()
        subTabContent.first().show()
        subTab.first().addClass("active")
      }
  });
}

export function tabsInit() {
  $(".my-tabs-content").hide()
  $(".my-tabs.active").each(function(){
      const tab_ativa = $(this).attr("data-content")
      $("#" + tab_ativa).show()

      if ($("#" + tab_ativa).find(".my-tabs").length > 0) {
        const subTab = $("#" + tab_ativa).find(".my-tabs")
        const subTabContent = $("#" + tab_ativa).find(".my-tabs-content")
        subTabContent.hide()
        subTabContent.first().show()
        subTab.first().addClass("active")

      }
  });

  $(".my-tabs-content.active.parcel").show()

}
