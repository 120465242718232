const btnExpandedFilter = document.querySelectorAll('.used-model-filter-expanded-button');

export function accordionsCreate() {
  $('.accordion .title').on('click', function(){
    const el = $(this)

    el.toggleClass('active')
    el.parent('.accordion')
      .find('.collapse')
      .slideToggle('fast')

    toggleTextHint(el);
  })
}

function toggleTextHint(el) {
  if(!$(el).hasClass('active')) {
    $(el).find('.icon span').html('Expandir');

    $(el).find('.icon i')
      .addClass('icon-seta-baixo-small')
      .removeClass('icon-seta-cima-small')
  } else {
    $(el).find('.icon span').html('Fechar');

    $(el).find('.icon i')
      .addClass('icon-seta-cima-small')
      .removeClass('icon-seta-baixo-small');
  }
}

// FUNCTION TO CONTROL ACCORDIONS ON USED CARS FILTER
export function controlAccordionsFilter() {
  btnExpandedFilter.forEach((btn) => {
    const icon = btn.querySelector('i');

    btn.addEventListener('click', () => {
      icon.classList.toggle('rotate-element');
    });
  });
}
