export function showCustomcardExpanded() {
  const cards = document.querySelectorAll(".custom-card");

  function toggleCardActive(card) {
    cards.forEach(c => {
      if (c !== card) {
        c.querySelector(".custom-card-content").classList.remove("active");
      }
    });
    card.querySelector(".custom-card-content").classList.toggle("active");
  }

  cards.forEach(card => {
    const cardBox = card.querySelector(".custom-card-box");
    const cardContent = card.querySelector(".custom-card-content");
    const expandedBox = card.querySelector(".custom-card-box-expanded");

    cardBox.addEventListener("click", function (event) {
      event.stopPropagation();
      toggleCardActive(card);
    });

    cardContent.addEventListener("click", function (event) {
      if (!expandedBox.contains(event.target)) {
        cardContent.classList.remove("active");
      }
    });
  });

  document.addEventListener("click", function (event) {
    const clickedCard = event.target.closest(".custom-card");

    if (!clickedCard) return;

    const activeCard = document.querySelector(".custom-card-content.active");
    if (activeCard && !clickedCard.contains(activeCard)) {
      toggleCardActive(clickedCard);
    }
  });
}
